<template>
  <div class="homePage">
    <div id="desktop-web-container" v-if="isDesktop">
      <p class="">请在手机打开网页链接</p>
    </div>
    <div v-bind:style="style" v-if="!isDesktop">
      <div
        class="section"
        v-for="(item, index) in list"
        :key="index"
        v-bind:style="{
          width:
            item.options &&
            item.options.class &&
            item.options.class.width &&
            item.options.class.width != 'auto'
              ? item.options.class.width
              : '100%',
        }"
      >
        <!-- 轮播组件 -->
        <wContentCarousel
          v-if="item.temp == 'ContentCarousel'"
          :content="item"
          @routeLink="openWeapp"
        >
          <template v-slot:default="$slot">
            <wx-open-launch-weapp
              id="launch-btn"
              :username="$slot.tags"
              :path="$slot.text"
            >
              <template>
                <div class="opacity"></div>
              </template>
            </wx-open-launch-weapp>
          </template>
        </wContentCarousel>
        <!-- banner组件 -->
        <wContentBanner
          v-if="item.temp == 'ContentBanner'"
          :content="item"
          @routeLink="openWeapp"
        >
          <template v-slot:default="$slot">
            <wx-open-launch-weapp
              id="launch-btn"
              :username="$slot.options.appid"
              :path="$slot.options.text"
            >
              <template>
                <div class="opacity"></div>
              </template>
            </wx-open-launch-weapp>
          </template>
        </wContentBanner>
      </div>
    </div>
  </div>
</template>
<script type="module">
import axios from "axios";
import config from "../../config/index.js";
import navigators from "../utils/navigator.js";
import utilsStorage from "../utils/storage.js";

import wContentBanner from "../components/wContentBanner";
import wContentCarousel from "../components/wContentCarousel";
import { ElMessageBox } from "element-plus";
import { h } from "vue";
// qa：https://api.qa.lululemon.cn
// prod：https://api.lululemon.cn
axios.defaults.baseURL = `https://api.lululemon.cn/estore/api/v4/estore-scheme/`;
export default {
  name: "homePage",
  data() {
    return {
      isDesktop: null,
      list: [],
      style: null,
    };
  },
  components: { wContentBanner, wContentCarousel },
  methods: {
    // 解析地址参数列表
    getLocationParam() {
      let search = window.location.search;
      const hash = window.location.hash;
      if (hash && !search) search = hash.replace("#/scheme", "");
      if (search) {
        const param = search
          .replace(/\?/g, "&")
          .split("&")
          .reduce((_h, _c) => {
            if (_c) {
              const key = _c.split("=")[0] || "";
              const value = _c.split("=")[1] || "";
              _h = { ..._h, [`${key}`]: value };
            }
            return _h;
          }, {});
        return param;
      }
      return "";
    },
    // 获取当前用户token
    getToken(wechatId) {
      return new Promise((resolve) => {
        const storage = utilsStorage.getTokenStorage();
        if (storage) resolve(storage);
        else {
          axios
            .get(`/getToken?wechatId=${wechatId}`)
            .then(({ data = {} }) => {
              const { token } = data.data;
              console.log("token", token);
              if (token) utilsStorage.setTokenStorage(token);
              resolve(token || "");
            })
            .catch((error) => {
              console.log(error);
              resolve({});
            });
        }
      });
    },
    // 获取wx.config参数
    getWxParam(wechatId) {
      return new Promise((resolve) => {
        axios
          .get(`/wechat/getJsSdkConfig?wechatId=${wechatId}`)
          .then(({ data = {} }) => {
            resolve(data.data || {});
          })
          .catch((error) => {
            console.log(error);
            resolve({});
          });
      });
    },
    // 处理wx config配置项
    getWxConfig(appId, timestamp, nonceStr, signature) {
      if (!appId || !timestamp || !nonceStr || !signature) {
        console.log("微信环境，获取wx.config参数异常");
        return;
      }
      const child = document.createElement("script");
      child.type = "text/javascript";
      child.src = "https://res.wx.qq.com/open/js/jweixin-1.6.0.js";
      document.body.appendChild(child);
      wx.config({
        // debug: true, // 调试时可开启
        appId, // 小程序appid
        timestamp, // 必填，填任意数字即可
        nonceStr, // 必填，填任意非空字符串即可
        signature, // 必填，填任意非空字符串即可
        jsApiList: ["chooseImage"], // 安卓上必填一个，随机即可
        openTagList: ["wx-open-launch-weapp"], // 填入打开小程序的开放标签名
        success(e) {
          console.log(e);
          this.isWeixin = true;
        },
      });
    },
    // 初始化content页面
    initContent(content = {}) {
      console.log(content);
      content = JSON.parse(content.content || "{}");
      const list = content.child || [];
      const style = content.style || "";
      this.list = list;
      this.style = style;
    },
    // 默认点击跳转scheme
    openWeapp() {
      this.urlScheme && (window.location.href = this.urlScheme);
    },
    showPopImage(src) {
      ElMessageBox({
        title: "",
        message: h(
          "div",
          {
            style: "display: flex; flex-direction: column;align-items: center",
          },
          [
            h("img", {
              style: "width: 20vw; height: 20vw; margin: 0 auto 20px",
              src,
            }),
            h(
              "p",
              {
                style: "white-space: nowrap; text-align:center;",
              },
              "截图保存或长按下载二维码图片"
            ),
          ]
        ),
        customStyle: {
          width: "70vw",
        },
        showCancelButton: false,
        showConfirmButton: false,
        confirmButtonText: "",
        cancelButtonText: "",
        beforeClose: (action, instance, done) => {
          console.log(action, instance);
          done();
        },
      }).finally((action) => {
        console.log(action);
      });
    },
  },
  async mounted() {
    const matchList = navigators.matchList();
    console.log(matchList);
    this.isWeixin = matchList.isWeixin;
    let isMobile = matchList.isMobile;
    this.isDesktop = matchList.isDesktop;
    let { wechatId, key } = this.getLocationParam();
    // 路径补参数
    if (!wechatId) wechatId = config.wechatId;
    const token = await this.getToken(wechatId);
    console.log({ wechatId }, { token }, { key });
    if (wechatId && token && key) {
      axios
        .get(`/urlScheme?wechatId=${wechatId}&key=${key}&token=${token}`)
        .then(({ data = {} }) => {
          const result = data.data || {};
          const content = result.indexList || [];
          // 初始化页面列表
          if (content.length) this.initContent(content[0]);
          // 在微信环境，默认使用jssdk跳转
          // if (wechatId && this.isWeixin) {
          //   this.getWxParam(wechatId).then(({ timestamp, nonceStr, signature }) => {
          //     this.getWxConfig(config.appId, timestamp, nonceStr, signature)
          //   })
          // }
          // 其他手机环境，获取跳转短链
          if (!this.isDesktop && isMobile) {
            // 手机端返回scheme
            if (result.url) {
              this.urlScheme = result.url;
              window.location.href = result.url;
            } else if (result.qrCodePath) {
              this.showPopImage(result.qrCodePath);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
};
</script>
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.opacity {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent !important;
}
.el-message-box__content {
  padding-left: 0;
  padding-right: 0;
}
</style>
