<template>
    <div class="module-content-banner" v-bind:style="style">
        <swiper :options="swiperOption" class="swiper-all" ref="mySwiper">
            <swiper-slide v-for="(item, index) in content.options.carousel" :key="index" v-if="content.options.carousel.length > 0">
                <div class="image">
                    <img :src="item.url" alt="" @click="routeLink(item.link)">
                    <wHotClick v-if="content.options.hotList" :content="content.options.hotList" @hotClick="routeLink"></wHotClick>
                    <div class="slot" v-if="isWeixin">
                      <slot :value="item"></slot>
                    </div>
                </div>
            </swiper-slide>
        </swiper>
    </div>
</template>

<script>
  import wHotClick from "./wHotClick";

  export default {
    props: {
      content: {
        type: Object
      },
      isWeixin: {
        type: Boolean
      }
    },
    components: { wHotClick },
    data() {
      return {
        popupVisible: false,
        style: '',
        width: '',
        swiperOption: {
          pagination: {
            el: '.swiper-pagination'
          },
          autoplay: this.content.options.autoplay === 1 ? true : false,
          loop: this.content.options.autoplay === 1 ? true : false,
          delay: this.content.options.interval ? true : false
        }
      }
    },
    watch: {
      content: {
        handler(val) {
          console.log(val, 'val')
          this.width = val.options['banner-width'];
          this.style = val.options.style
          this.content = val
        }
      }
    },
    mounted() {},
    methods: {
      routeLink (link) {
        if (this.content.options.hotList) this.$emit('routeLink', link);
        else this.$emit('routeLink', link);
      }
    }
  }
</script>

<style scoped lang="scss">
    .module-content-banner{
        width:100%!important;
    }
    .module-content-banner {
        box-sizing: border-box;
        font-size: 0;
        .image{
            position: relative;
            display: inline-block;
            max-width: 100vw;
            img {
              max-width: 100vw;
            }
        }
        .slot {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }
    }
    .swiper-container{
        --swiper-theme-color: #ff6600;
        --swiper-pagination-color: #00ff33;/* 两种都可以 */
    }
</style>
