<template>
    <div class="module-content-banner" v-bind:style="style">
        <div class="image" 
           @click="routeLink(content.options.link)"
           v-bind:style="{width: width}">
          <img :src="content.options.banner" alt="">
          <wHotClick v-if="content.options.hotList" :content="content.options.hotList" @hotClick="routeLink"></wHotClick>
          <div class="slot" v-if="isWeixin">
            <slot :value="content"></slot>
          </div>
        </div>
    </div>
</template>

<script>
  import wHotClick from "./wHotClick";

  export default {
    props: {
      content: {
        type: Object
      },
      isWeixin: {
        type: Boolean
      }
    },
    components: {wHotClick},
    data() {
      return {
        popupVisible: false,
        style: '',
        width: ''
      }
    },
    watch: {
      content: {
        handler(val) {
          console.log(val, 'val')
          this.width = val.options['banner-width'];
          this.style = val.options.style
          this.content = val
        }
      }
    },
    mounted() {
      let vm = this
    },
    methods: {
      routeLink (link) {
        this.$emit('routeLink', link);
      }
    }
  }
</script>

<style scoped lang="scss">
.module-content-banner{
    width:100%!important;
}
.module-content-banner {
    box-sizing: border-box;
    font-size: 0;
    max-width: 100vw;
    .image{
        position: relative;
        display: inline-block;
        max-width: 100vw;
        img {
          max-width: 100vw;
        }
        .slot {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }
    }
}
</style>
