// 备用wechatId，用于路径不传参时使用
const importFn = require.context('./env/', false, /\.js$/)
const env = process.env.NODE_Label
function install () {
  let result = {}
  importFn.keys().forEach(path => {
    const component = importFn(path).default
    if (component.name == env) result = component
  })
  return result
}
export default install()