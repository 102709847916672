export default {
  // 设置token缓存
  setTokenStorage(token) {
    const timeStamp = new Date().getTime()
    // 默认30天缓存
    const expireTime = timeStamp + 30 * 24 * 60 * 60 * 1000
    const value = JSON.stringify({ expireTime, value: token })
    localStorage.setItem("schemeToken", value)
  },
  // 获取token缓存
  getTokenStorage() {
    let storage = localStorage.getItem('schemeToken')
    if (storage) {
      storage = JSON.parse(storage)
      const timeStamp = new Date().getTime()
      if (timeStamp < storage.expireTime) return storage.value
      localStorage.removeItem('schemeToken')
    }
    return ''
  }
}