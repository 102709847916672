export default {
  isWeixin () {
    return /micromessenger/.test(navigator.userAgent.toLowerCase())
  },
  isIos () {
    return /(iPod|iPhone|iPad)/.test(navigator.userAgent)
  },
  isAndroid () {
    return /(Android|Adr)/.test(navigator.userAgent)
  },
  isWXWork () {
    return navigator.userAgent.toLowerCase().match(/wxwork/i) == 'wxwork'
  },
  // 判断当前环境
  matchList () {
    let ua = navigator.userAgent.toLowerCase()
    let isWXWork = ua.match(/wxwork/i) == 'wxwork'
    let isWeixin = !isWXWork && /micromessenger/.test(navigator.userAgent.toLowerCase())
    let isMobile = false
    let isDesktop = false
    if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|IEMobile)/i)) {
      isMobile = true
    } else {
      isDesktop = true
    }
    return {isMobile, isDesktop, isWeixin}
  }
}
