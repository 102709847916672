<template>
    <div class="module-content-hotspots">
        <div class="item-spot" v-for="item in content"  v-bind:style="item.style" @click="routeLink(item.link)">
        </div>
    </div>
</template>

<script>

  export default {
    props: {
      content: {
        type: Array
      }
    },
    data() {
      return {
        _content: []
      }
    },
    watch: {
      content: {
        handler(val) {}
      }
    },
    created() {
      let vm = this
      vm.content.forEach(v => {
        v.style = {
          left: `${v.left / v.allWidth * 100}%`,
          width: `${v.width / v.allWidth * 100}%`,
          top: `${v.top / v.allHeight * 100}%`,
          height: `${v.height / v.allHeight * 100}%`
        }
      })
    },
    methods: {
      routeLink (link) {
        this.$emit('hotClick', link);
      }
    }
  }
</script>

<style scoped lang="scss">
    .module-content-hotspots {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        .item-spot{
            position: absolute;
            //background: rgba(255, 255, 255, .3);
        }
    }
</style>
