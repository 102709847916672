<template>
  <homePage />
</template>
<script>
import homePage from "./pages/homePage.vue";

export default {
  name: "App",
  components: {
    homePage,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body {
  margin: 0;
  width: 100vw;
}
</style>
